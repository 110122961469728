import React from "react";
import DataItem from "../DataItem/DataItem";
import DataList from "../DataList/DataList";
import AppButton from "../AppButton/AppButton";
import { useNavigate } from "react-router-dom";
import "./Faq.css";
import BackButton from "../BackButton/BackButton";
import { motion } from "framer-motion";
import { directionVariants } from "../../utils/directionOptions";
import { setDirection } from "../../redux/actions/actions";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import { translations } from "../../utils/translations/translations";
import { useSelector } from "react-redux";

function Faq() {
    const [index, setIndex] = React.useState(0);
    const language = useSelector((state) => state.language);
    const direction = useSelector((state) => state.direction);
    React.useEffect(() => {
        setIndex(0);
    }, []);
    const navigate = useNavigate();
    return (
        <motion.section
            className="faq"
            initial={direction ? "fromLeft" : "fromRight"}
            animate={{ x: 0, opacity: 1, transition: { duration: 0.2, delay: 0.2 } }}
            exit={direction ? "exitToRight" : "exitToLeft"}
            variants={directionVariants}
        >
            <BurgerMenu color="var(--white)" />
            <BackButton text={translations[language].backButton} path={-1} currentClass="white moved-right" title={translations[language].textTips.faq} />
            <div className="empty-block"></div>
            <DataList currentClass="data-list-faq">
                <DataItem title={translations[language].faq.title1} index={index} currentIndex={1} setIndex={setIndex}>
                    <p className="data-item__text">{translations[language].faq.text1normal1}</p>
                </DataItem>
                <DataItem title={translations[language].faq.title2} index={index} currentIndex={3} setIndex={setIndex}>
                    <p className="data-item__text">
                        {translations[language].faq.text2normal1}
                        <br />
                        {translations[language].faq.text2normal2}
                    </p>
                </DataItem>
                <DataItem title={translations[language].faq.title3} index={index} currentIndex={4} setIndex={setIndex}>
                    <p className="data-item__text">
                        {translations[language].faq.text3normal1}
                        <b>{translations[language].faq.text3bold1}</b>
                        {translations[language].faq.text3normal2}
                        <b>{translations[language].faq.text3bold2}</b>
                        {translations[language].faq.text3normal3}
                        <b>{translations[language].faq.text3bold3}</b>
                        {translations[language].faq.text3normal4}
                        <b>{translations[language].faq.text3bold4}</b>
                        {translations[language].faq.text3normal5}
                        {/* <b>{translations[language].faq.text3bold5}</b>
            {translations[language].faq.text3normal6} */}
                        <br />
                        <b>{translations[language].faq.text3bold6}</b>
                        {translations[language].faq.text3normal7}
                        <br />
                        <b>{translations[language].faq.text3bold7}</b>
                    </p>
                </DataItem>
                <DataItem title={translations[language].faq.title4} index={index} currentIndex={5} setIndex={setIndex}>
                    <p className="data-item__text">{translations[language].faq.text4normal1}</p>
                </DataItem>
                <DataItem title={translations[language].faq.title5} index={index} currentIndex={6} setIndex={setIndex}>
                    <p className="data-item__text">
                        {translations[language].faq.text5normal1}
                        {/* <b>{translations[language].faq.text5bold1}</b>
            {translations[language].faq.text5normal2} */}
                    </p>
                </DataItem>
                <DataItem title={translations[language].faq.title6} index={index} currentIndex={7} setIndex={setIndex}>
                    <p className="data-item__text">
                        {translations[language].faq.text6normal1}
                        <b>{translations[language].faq.text6bold1}</b>
                        {translations[language].faq.text6normal2}
                        <b>{translations[language].faq.text6bold2}</b>
                        {translations[language].faq.text6normal3}
                    </p>
                </DataItem>
                <DataItem title={translations[language].faq.title7} index={index} currentIndex={8} setIndex={setIndex}>
                    <p className="data-item__text">{translations[language].faq.text7normal1}</p>
                </DataItem>
                {/* <DataItem
        title={translations[language].faq.title17}
        index={index}
        currentIndex={9}
        setIndex={setIndex}>
          <p className='data-item__text'>
            {translations[language].faq.text17}
          </p>
        </DataItem> */}
                <DataItem title={translations[language].faq.title15} index={index} currentIndex={10} setIndex={setIndex}>
                    <p className="data-item__text">{translations[language].faq.text15}</p>
                </DataItem>
                <DataItem title={translations[language].faq.title8} index={index} currentIndex={11} setIndex={setIndex}>
                    <p className="data-item__text">
                        {translations[language].faq.text8normal1}
                        <b>{translations[language].faq.text8bold1}</b>
                        {translations[language].faq.text8normal2}
                    </p>
                    <p className="data-item__text">
                        {translations[language].faq.text8normal3}
                        <span
                            onClick={() => {
                                setDirection(true);
                                navigate("/instruction");
                            }}
                            className="data-item__link"
                        >
                            {translations[language].faq.text8link1}
                        </span>
                        <br />
                        {translations[language].faq.text8normal4}
                        <br />
                        {translations[language].faq.text8normal5}
                        <span
                            onClick={() => {
                                setDirection(true);
                                navigate("/my-vpn");
                            }}
                            className="data-item__link"
                        >
                            {translations[language].faq.text8link2}
                        </span>
                        {translations[language].faq.text8normal6}
                        <br />
                        {translations[language].faq.text8normal7}
                        <br />
                        {translations[language].faq.text8normal8}
                        <br />
                        {translations[language].faq.text8normal10}
                    </p>
                    <p className="data-item__text">
                        {translations[language].faq.text8normal9}
                        <br />
                        {translations[language].faq.text8normal11}
                    </p>
                </DataItem>
                <DataItem title={translations[language].faq.title9} index={index} currentIndex={12} setIndex={setIndex}>
                    <p className="data-item__text">
                        {translations[language].faq.text9normal1}
                        <b>{translations[language].faq.text9bold1}</b>
                        {translations[language].faq.text9normal2}
                        <br />
                        {translations[language].faq.text9normal3}
                        <b>{translations[language].faq.text9bold2}</b>
                        {translations[language].faq.text9normal4}
                        <b>{translations[language].faq.text9bold3}</b>
                        {translations[language].faq.text9normal5}
                    </p>
                </DataItem>
                <DataItem title={translations[language].faq.title10} index={index} currentIndex={13} setIndex={setIndex}>
                    <p className="data-item__text">
                        {translations[language].faq.text10normal1}
                        <b>{translations[language].faq.text10bold1}</b>
                        {translations[language].faq.text10normal2}
                        <br />
                        <b>{translations[language].faq.text10bold2}</b>
                        {translations[language].faq.text10normal3}
                        <br />
                        <b>{translations[language].faq.text10bold3}</b>
                        {translations[language].faq.text10normal4}
                        <br />
                        {/* <b>{translations[language].faq.text10bold4}</b> */}
                        {translations[language].faq.text10normal5}
                    </p>
                </DataItem>
                <DataItem title={translations[language].faq.title12} index={index} currentIndex={14} setIndex={setIndex}>
                    <p className="data-item__text">{translations[language].faq.text12}</p>
                </DataItem>
                <DataItem title={translations[language].faq.title14} index={index} currentIndex={16} setIndex={setIndex}>
                    <p className="data-item__text">
                        {translations[language].faq.text14normal1}
                        <br />
                        {translations[language].faq.text14normal2}
                        <br />
                        {translations[language].faq.text14normal3}
                        {/* <br />
            {translations[language].faq.text14normal4} */}
                        <br />
                        {translations[language].faq.text14normal5}
                        <span
                            onClick={() => {
                                setDirection(true);
                                navigate("/subscription");
                            }}
                            className="data-item__link"
                        >
                            {translations[language].faq.text14link}
                        </span>
                        {translations[language].faq.text14normal6}
                        <br />
                        {translations[language].faq.text14normal7}
                        <br />
                        {translations[language].faq.text14normal8}
                        <br />
                        {translations[language].faq.text14normal9}
                        <br />
                        {translations[language].faq.text14normal13}
                        <br />
                        {translations[language].faq.text14normal10}
                        <br />
                        {translations[language].faq.text14normal11}
                        <br />
                        {translations[language].faq.text14normal12}
                    </p>
                </DataItem>
                <DataItem title={translations[language].faq.title18} index={index} currentIndex={17} setIndex={setIndex}>
                    <p className="data-item__text">{translations[language].faq.text18}</p>
                </DataItem>
                <DataItem title={translations[language].faq.title15} index={index} currentIndex={18} setIndex={setIndex}>
                    <p className="data-item__text">{translations[language].faq.text15}</p>
                </DataItem>
                <DataItem title={translations[language].faq.title19} index={index} currentIndex={19} setIndex={setIndex}>
                    <p className="data-item__text">
                        <b>{translations[language].faq.text19bold1}</b>
                        <br />
                        <br />
                        {translations[language].faq.text19normal1}
                        <br />
                        {translations[language].faq.text19normal2}
                        <br />
                        <br />
                        {translations[language].faq.text19normal3}
                        <br />
                        <br />
                        <b>{translations[language].faq.text19bold2}</b>
                        <br />
                        {translations[language].faq.text19normal4}
                        <br />
                        {translations[language].faq.text19normal5}
                        <br />
                        <br />
                        <b>{translations[language].faq.text19bold3}</b>
                        <br />
                        {translations[language].faq.text19normal6}
                        <br />
                        {translations[language].faq.text19normal7}
                        <br />
                        {translations[language].faq.text19normal8}
                        <br />
                        <br />
                        <b>{translations[language].faq.text19bold4}</b>
                        <br />
                        {translations[language].faq.text19normal9}
                        <br />
                        {translations[language].faq.text19normal10}
                        <br />
                        <br />
                        <b>{translations[language].faq.text19bold5}</b>
                        <br />
                        <br />
                        {translations[language].faq.text19normal11}
                    </p>
                </DataItem>
            </DataList>
            <div className="faq__button-box">
                <AppButton
                    text={translations[language].appButton.mainMenu}
                    currentClass="secondary margin-bottom white"
                    handler={() => {
                        setDirection(true);
                        navigate("/");
                    }}
                />
                <AppButton
                    text={translations[language].appButton.chooseTariff}
                    currentClass="primary violet"
                    handler={() => {
                        setDirection(true);
                        navigate("/tariffes");
                    }}
                />
            </div>
        </motion.section>
    );
}

export default Faq;
